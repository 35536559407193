<template>
    <div>
        <v-row>
            <v-col>
                <v-btn @click="Insert()">เพิ่ม</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table :headers="headers" :items="ds" class="elevation-1">
                    <template v-slot:item.action="{ item }">
                        <v-btn fab dark small color="lime" @click="Edit(item)">
                            <v-icon dark>fa fa-pen</v-icon>
                        </v-btn>
                        <v-btn fab dark small color="red" @click="Delete(item)">
                            <v-icon dark>fa fa-trash-alt</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import axios from "axios";
    export default {
        data() {
            return {
                headers: [
                    {
                        text: "รหัสสาขา",
                        align: "left",
                        sortable: true,
                        value: "OrganizationCode"
                    },
                    {
                        text: "ชื่อสาขา",
                        align: "left",
                        sortable: true,
                        value: "OrganizationName"
                    },
                    {
                        text: "หมายเลขโทรศัพ",
                        align: "left",
                        sortable: true,
                        value: "OrganizationTel"
                    },
                    {
                        text: "หมายเหตุ",
                        align: "left",
                        sortable: true,
                        value: "OrganizationRemark"
                    },
                    { width: 120, text: "", value: "action", sortable: false }
                ],
                ds: []
            };
        },
        mounted() {
            this.$nextTick(() => {
                if (this.$store.state.User.UserLevel == "M") {
                    this.Select();
                } else {
                    this.$router.push("/Member");
                }
            });
        },
        methods: {
            Select() {
                axios.get("Api/V1/Select/Select/Organization").then(res => {
                    if (res.status == 200) {
                        this.ds = res.data;
                    }
                });
            },
            Insert() {
                this.$router.push("/Org/Add");
            },
            Edit(v) {
                this.$router.push("/Org/Add?OrganizationId=" + v.OrganizationId);
            },
            Delete(v) {
                axios.delete("Api/V1/Delete/Delete/Organization?OrganizationId=" + v.OrganizationId).then(res => {
                    if (res.status == 200) {
                        this.Select();
                    }
                });
            }
        }
    };
</script>
<style></style>
